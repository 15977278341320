import service from "../../utils/request";

let url = "";
if (process.env.NODE_ENV === 'production') {
    url = "/lgiot/pdsgw";
} else {
    url = "/lgiot/product/pdsgw";
}

/**
 * 查询公告
 * @param {
 *  *currPage 当前页码
 *  *pageSize 每页返回记录数
 *  startTime 开始时间
 *  endTime 结束时间
 *  params 查询参数
 * } data
 * @returns
 */
const queryNotice = data => {
    return service({
        url: url + '/notice/query',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 跟据公告code查询详情
 * @param {
 *  *code 公告编号
 * } data
 * @returns
 */
const getNoticeDetailByCode = data => {
    return service({
        url: url + '/notice/detail',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 新建产品
 * @param {
 *  *categoryCode 分类编码
 *  icon 产品拟物图
 *  model 型号
 *  *name 名称
 *  networkModeData 支持的配网方式JSON格式: [{"networkModeCode":"1001","isDefault":"Y"},{"networkModeCode":"1002","isDefault":"N"}]
 *  *schemeType 方案类型 - 1:非嵌入式系统接入 2:嵌入式系统接入 3:云对云接入
 *  *protocolType 协议类型
 *  remark 备注
 * } data
 * @returns
 */
const addProduct = data => {
    return service({
        url: url + '/product/add',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 分页查询产品
 * @param {
 *  categoryCode 分类编码
 *  *currPage 当前页码
 *  *pageSize 每页返回记录数
 *  pid PID
 *  productName 产品名称
 *  productStatus 产品状态 - 1:开发中; 2:申请发布; 3:已发布
 *  protocolType 协议类型
 * } data
 * @returns
 */
const queryProduct = data => {
    return service({
        url: url + '/product/query',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 删除产品
 * @param {
 *  *productCode 产品编号
 * } data
 * @returns
 */
const deleteProduct = data => {
    return service({
        url: url + '/product/delete',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 获取产品详情
 * @param {
 *  *productCode 产品编码
 * } data
 * @returns
 */
const getProductDetail = data => {
    return service({
        url: url + '/product/detail',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 修改产品
 * @param {
 *  *code 产品编码
 *  icon 产品拟物图
 *  model 型号
 *  name 名称
 *  remark 备注
 *  configureService 配置服务json字符串
 *  moduleId 模组ID
 * } data
 * @returns
 */
const modifyProduct = data => {
    return service({
        url: url + '/product/modify',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 新增属性
 * @param {
 *  *attributeKey 属性标识
 *  *authority 访问权限 - 1:只下发(wo); 2:只上报(ro); 3:可下发可上报(rw)
 *  *dataType 数据类型 - 1:整数型(Integer); 2:小数型(Float); 3:枚举型(Enum); 4:布尔型(Bool); 5:字符型(String); 6:透传型(Raw); 7:故障型;
 *  enumValues 枚举值定义 - 格式：[{"k":"low","v":1},{"k":"middle","v":2},{"k":"high","v":3}]
 *  maxLength 最大长度
 *  maxValue 最大值
 *  minValue 最小值
 *  *name 名称
 *  *productCode 产品编码
 *  *remark 功能编码
 *  *serviceCode 功能编码
 *  stepValue 步长值
 *  unit 单位
 * } data
 * @returns
 */
const addProductAttribute = data => {
    return service({
        url: url + '/product/attribute/add',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 修改功能属性
 * @param {
 *  *attributeCode 属性编码
 *  *dataType 数据类型 - 1:整数型(Integer); 2:小数型(Float); 3:枚举型(Enum); 4:布尔型(Bool); 5:字符型(String); 6:透传型(Raw); 7:故障型;
 *  enumValues 枚举值定义 - 格式：[{"k":"low","v":1},{"k":"middle","v":2},{"k":"high","v":3}]
 *  maxLength 最大长度
 *  maxValue 最大值
 *  minValue 最小值
 *  *name 名称
 *  *productCode 产品编码
 *  remark 备注
 *  *serviceCode 功能编码
 *  stepValue 步长值
 *  unit 单位
 * } data
 * @returns
 */
const modifyProductAttribute = data => {
    return service({
        url: url + '/product/attribute/modify',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 新增功能
 * @param {
 *  attrs 属性列表
 *  *name 名称
 *  *productCode 产品编码
 *  remark 备注
 *  serviceKey 功能标识
 *  type 功能类型 - 1:标准功能; 2:自定义功能
 * } data
 * @returns
 */
const addProductService = data => {
    return service({
        url: url + '/product/service/add',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 删除产品功能
 * @param {
 *  *productCode 产品编号
 *  *serviceCode 功能编号
 * } data
 * @returns
 */
const deleteProductService = data => {
    return service({
        url: url + '/product/service/delete',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 获取产品功能列表
 * @param {
 *  *productCode 产品编码
 * } data
 * @returns
 */
const queryProductServiceList = data => {
    return service({
        url: url + '/product/service/list',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 修改功能
 * @param {
 *  attrs 属性列表
 *  *name 名称
 *  *productCode 产品编码
 *  remark 备注
 *  *serviceCode 功能编码
 * } data
 * @returns
 */
const modifyProductService = data => {
    return service({
        url: url + '/product/service/modify',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 删除属性功能
 * @param {
 *  *attributeCode 属性编号
 *  *productCode 产品编号
 * } data
 * @returns
 */
const deleteProductAttribute = data => {
    return service({
        url: url + '/product/attribute/delete',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 获取属性详情
 * @param {
 *  *attributeCode 属性编码
 *  *productCode 产品编码
 * } data
 * @returns
 */
const getProductAttributeDetail = data => {
    return service({
        url: url + '/product/attribute/detail',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
};

/**
 * 查看烧录信息
 * @param {
 *  *productCode 产品编号
 * }
 * @returns {AxiosPromise}
 */
const getProductBurningInfo = data => {
    return service({
        url: url + '/product/burningInfo',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
};

/**
 * 新建固件
 * @param {
 *  *name 固件名称
 *  *productCode 产品编号
 *  *remark 注备
 *  *url 下载地址
 *  *type 固件类型 - 1:含SDK固件; 2:MCU固件;
 * }
 * @returns {AxiosPromise}
 */
const addFirmware = data => {
    return service({
        url: url + '/firmware/add',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
};

/**
 * 删除固件
 * @param {
 *  *id 固件ID
 * }
 * @returns {AxiosPromise}
 */
const delFirmware = data => {
    return service({
        url: url + '/firmware/delete',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
};

/**
 * 发布固件
 * @param {
 *  *id 固件ID
 * }
 * @returns {AxiosPromise}
 */
const pubFirmware = data => {
    return service({
        url: url + '/firmware/publish',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
};

/**
 * 固件查询
 * @param {
 *  *currPage 当前页码
 *  *pageSize 每页返回记录数
 *  *productCode 产品编号
 *  type 固件类型 - 1:含SDK固件; 2:MCU固件;
 * }
 * @returns {AxiosPromise}
 */
const queryFirmware = data => {
    return service({
        url: url + '/firmware/query',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
};

/**
 * 新建应用
 * @param {
 *  *name 应用名称
 *  *productCode 产品编号
 *  *remark 注备
 *  *url 下载地址
 *  *iosUrl ios地址
 *  hdUrl hd地址
 * }
 * @returns {AxiosPromise}
 */
const addApply = data => {
    return service({
        url: url + '/apply/add',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
};

/**
 * 删除应用
 * @param {
 *  *applyCode 应用编号
 * }
 * @returns {AxiosPromise}
 */
const delApply = data => {
    return service({
        url: url + '/apply/delete',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 发布应用
 * @param {
 *  *applyCode  应用code
 * }
 * @returns {AxiosPromise}
 */
const pubApply = data => {
    return service({
        url: url + '/apply/publish',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
};

/**
 * 应用查询接口
 * @param {
 *  *currPage 当前页码
 *  *pageSize 每页返回记录数
 *  *productCode 产品编号
 * }
 * @returns {AxiosPromise}
 */
const queryApply = data => {
    return service({
        url: url + '/apply/query',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
};

/**
 * 导出功能定义
 * @param {
 *  *productCode 产品编号
 * }
 * @returns {AxiosPromise}
 */
const exportFunction = data => {
    return service({
        url: url + '/exportTemplate/exportFunction',
        method: 'post',
        isToken: true,
        data,
        responseType: 'blob',
        contentType: 'application/json;charset=UTF-8'
    })
};

/**
 * 扫码回调
 * @param {
 *  *key 回调ID
 * }
 * @returns {AxiosPromise}
 */
const scanCode = data => {
    return service({
        url: url + '/device/scanCode',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
};

/**
 * 发布产品
 * @param {
 *  *apply 应用版本
 *  *categoryCode 分类编码
 *  *firmware 固件版本
 *  *icon 产品拟物图
 *  *model 型号
 *  *name 名称
 *  *productCode 产品编号
 * }
 * @returns {AxiosPromise}
 */
const publishProduct = data => {
    return service({
        url: url + '/product/publish',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
};

/**
 * 固件查询接口
 * @param {
 *  *productCode 产品编号
 *  status 固件状态 - 1:未发布; 2:已发布
 * }
 * @returns {AxiosPromise}
 */
const queryFirmwareList = data => {
    return service({
        url: url + '/firmware/queryList',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
};

/**
 * 应用查询接口
 * @param {
 *  *productCode 产品编号
 *  status 固件状态 - 1:未发布; 2:已发布
 * }
 * @returns {AxiosPromise}
 */
const queryApplyList = data => {
    return service({
        url: url + '/apply/queryList',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
};

/**
 * 查看固件资源信息
 * @returns {AxiosPromise}
 */
const getFirmwareResource = data => {
    return service({
        url: url + '/product/resource',
        method: 'post',
        isToken: false,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
};

/**
 * 查看App资源下载地址
 * @returns {AxiosPromise}
 */
const getPanelResource = data => {
    return service({
        url: url + '/product/resource/app',
        method: 'post',
        isToken: false,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
};


/**
 * 分页查询产品,IOT平台设备
 * @param {
 *  *currPage 当前页码
 *  isOnline 设备状态 - Y:在线; 2:离线
 *  name 设备名称
 *  ownerId 用户ID
 *  *pageSize 每页返回记录数
 *  productCode 产品编号
 *  roomId 空间ID
 *  status 状态 - 1:未激活; 2:已激活,
 *  type 设备类型 1.正常 2.虚拟 3.预览
 * }
 * @returns {AxiosPromise}
 */
const queryDevice = data => {
    return service({
        url: url + '/device/query',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
};

/**
 * 删除虚拟设备
 * @param {
 *  *deviceId 设备ID
 * }
 * @returns {AxiosPromise}
 */
const delVirtualDevice = data => {
    return service({
        url: url + '/device/deleteVirtual',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 新建应用前检查
 * @param {
 *  *name 应用版本号
 *  *productCode 产品编号
 * }
 * @returns {*}
 */
const appLyAddCheck = data => {
    return service({
        url: url + '/apply/addCheck',
        method: 'post',
        isToken: false,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 复制产品
 * @param {
 *  model 产品型号
 *  *name 名称
 *  *productCode 产品编号
 *  rnApplyId 应用ID
 *  pid pid
 * }
 * @returns {*}
 */
const copeProduct = data => {
    return service({
        url: url + '/product/copy',
        method: 'post',
        isToken: true,
        data,
        timeout: 60*1000,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 模组查询接口
 * @returns {*}
 */
const queryModuleList = () => {
    return service({
        url: url + '/module/queryList',
        method: 'get',
        isToken: true,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 公告已读记录查询接口
 * @param {
 *     *code 公告编号;多个逗号隔开
 * }
 * @returns {*}
 */
const queryReadNotice = data => {
    return service({
        url: url + '/notice/queryRead',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 新建用户已读记录
 * @param {
 *     *code 公告编号;多个逗号隔开
 * }
 * @returns {*}
 */
const addReadNotice = data => {
    return service({
        url: url + '/notice/addRead',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 配置服务查询接口
 * @param {
 *     categoryCode 品类code;0代表所有品类;多个品类逗号隔开
 * }
 * @returns {*}
 */
const queryConfigureServiceList = data => {
    return service({
        url: url + '/configureService/queryList',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 是否有未读公告消息
 * @param data
 * @returns {*}
 */
const hasUnreadNotice = () => {
    return service({
        url: url + '/notice/hasUnread',
        method: 'get',
        isToken: true,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 跟据通信类型获取配网方式列表
 * @param {
 *     *protocolType 通信协议
 * }
 * @returns {*}
 */
const getNetworkModeListByProtocolType = data => {
    return service({
        url: url + '/networkmode/listByProtocolType',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 生成预览码
 * @param {
 *     *applyCode 应用编号
 * }
 * @returns {*}
 */
const generatePreviewCode = data => {
    return service({
        url: url + '/apply/generatePreviewCode',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 通过品类编码查找模组列表
 * @param {
 *     *categoryCode 品类编号
 * }
 * @returns {*}
 */
const getModuleListByCategoryCode = data => {
    return service({
        url: url + '/module/listByCategoryCode',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 获取产品标准功能列表
 * @param {
 *     *categoryCode 品类编码
 *     *productCode 产品编码
 * }
 * @returns {*}
 */
const getStandardServiceList = data => {
    return service({
        url: url + '/product/service/standard/list',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 从标准功能新增功能
 * @param {
 *     *categoryCode 品类编码
 *     *productCode 产品编码
 *     *serviceIds 服务ID列表，多个用,分隔
 * }
 * @returns {*}
 */
const addFromStandard = data => {
    return service({
        url: url + '/product/service/addFromStandard',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}





export default {
    queryNotice,
    getNoticeDetailByCode,
    addProduct,
    queryProduct,
    deleteProduct,
    getProductDetail,
    modifyProduct,
    addProductAttribute,
    modifyProductAttribute,
    addProductService,
    deleteProductService,
    queryProductServiceList,
    modifyProductService,
    deleteProductAttribute,
    getProductAttributeDetail,
    getProductBurningInfo,
    addFirmware,
    delFirmware,
    pubFirmware,
    queryFirmware,
    addApply,
    delApply,
    pubApply,
    queryApply,
    exportFunction,
    scanCode,
    publishProduct,
    queryFirmwareList,
    queryApplyList,
    getFirmwareResource,
    getPanelResource,
    queryDevice,
    delVirtualDevice,
    appLyAddCheck,
    copeProduct,
    queryModuleList,
    queryReadNotice,
    addReadNotice,
    queryConfigureServiceList,
    hasUnreadNotice,
    getNetworkModeListByProtocolType,
    generatePreviewCode,
    getModuleListByCategoryCode,
    getStandardServiceList,
    addFromStandard
}
