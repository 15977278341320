import app from "../../main";

const maintain = {

    namespaced: true,

    state: {

        personnelManagementDialogVisible: false,

        addPersonnelDialogVisible: false,

        releaseFirmwareDialogVisible: false,

        addFirmwareTestDialogVisible: false,

        addWhiteListDialogVisible: false,

        addUserDialogVisible: false,

    },

    getters: {

    },

    mutations: {

        SET_PERSONNEL_MANAGEMENT_DIALOG_VISIBLE: (state, personnelManagementDialogVisible) => {
            state.personnelManagementDialogVisible = personnelManagementDialogVisible
        },

        SET_ADD_PERSONNEL_DIALOG_VISIBLE: (state, addPersonnelDialogVisible) => {
            state.addPersonnelDialogVisible = addPersonnelDialogVisible;
        },

        SET_RELEASE_FIRMWARE_DIALOG_VISIBLE: (state, releaseFirmwareDialogVisible) => {
            state.releaseFirmwareDialogVisible = releaseFirmwareDialogVisible;
        },

        SET_ADD_FIRMWARE_TEST_DIALOG_VISIBLE: (state, addFirmwareTestDialogVisible) => {
            state.addFirmwareTestDialogVisible = addFirmwareTestDialogVisible;
        },

        SET_ADD_WHITE_LIST_DIALOG_VISIBLE: (state, addWhiteListDialogVisible) => {
            state.addWhiteListDialogVisible = addWhiteListDialogVisible;
        },

        SET_ADD_USER_DIALOG_VISIBLE: (state, addUserDialogVisible) => {
            state.addUserDialogVisible = addUserDialogVisible;
        }

    },

    actions: {

        closePersonnelManagementDialog({commit}) {
            commit('SET_PERSONNEL_MANAGEMENT_DIALOG_VISIBLE', false);
        },

        closeAddPersonnelDialog({commit}) {
            commit('SET_ADD_PERSONNEL_DIALOG_VISIBLE', false);
        },

        closeReleaseFirmwareDialog({commit}) {
            commit('SET_RELEASE_FIRMWARE_DIALOG_VISIBLE', false);
        },

        closeAddFirmwareTestDialog({commit}) {
            commit('SET_ADD_FIRMWARE_TEST_DIALOG_VISIBLE', false);
        },

        closeAddWhiteListDialog({commit}) {
            commit('SET_ADD_WHITE_LIST_DIALOG_VISIBLE', false)
        },

        closeAddUserDialog({commit}) {
            commit('SET_ADD_USER_DIALOG_VISIBLE', false);
        }

    }

}

export default maintain;
