import user from '@/api/modules/user';
import auth from '@/api/modules/auth';
import enterprise from '@/api/modules/enterprise';
import category from '@/api/modules/category';
import product from '@/api/modules/product';
import menu from '@/api/modules/menu';
import role from '@/api/modules/role';
import control from '@/api/modules/control';
import empower from "@/api/modules/empower";
import guidance from "@/api/modules/guidance";
import device from "@/api/modules/device";


export default {
    user,
    auth,
    enterprise,
    category,
    product,
    menu,
    role,
    control,
    empower,
    guidance,
    device
}
